/*
 *  Axon Labs: Portal Config
 *  ==============================
 *   - Client ID: ducducnyc
 */

// Import components
import Login from './components/Login';
// const header = require('./components/Header').default;
// const home = require('./components/Home').default;
// const footer = require('./components/Footer').default;

// // Import styles
// require('./styles/index.less');

// // Initialize library
// const ClientConfig = {
//   clientId: "ducducnyc",
//   components: {
//     header: header(),
//     home: home(),
//     footer: footer()
//   },

//   // Global assets
//   assets: []
// };

// Import styles
import Styles from './styles';

// Client config
const ClientConfig = {
    accountId: 'arthaus',
    clientId: '_default',
    styles: Styles,
    components: {
        login: Login
    }
};

export default ClientConfig;
