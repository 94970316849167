// Require portal config
import ClientConfig from '_config';

// Setup config export
const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/sample-page',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'custom', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    i18n: 'en',
    rtlLayout: false,
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    firebase: {
        apiKey: 'AIzaSyCzy2jmjEppUMa3nB4Yaolv4rhWShjM3ZM',
        authDomain: 'arthaus-us.firebaseapp.com',
        databaseURL: 'https://arthaus-us-default-rtdb.firebaseio.com',
        projectId: 'arthaus-us',
        storageBucket: 'arthaus-us.appspot.com',
        messagingSenderId: '156671318330',
        appId: '1:156671318330:web:6d6b378f83ec9cd4925803',
        measurementId: 'G-17CS7BVBKF'
    },
    auth0: {
        client_id: 'HvYn25WaEHb7v5PBT7cTYe98XATStX3r',
        domain: 'demo-localhost.us.auth0.com'
    },
    app: ClientConfig
};

export default config;
