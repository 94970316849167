// Global Options
import colors from './_app/colors.module.scss';

// Style config
const Styles = {
    colors
};

// Export
export default Styles;
