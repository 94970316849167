/*
 *  Login
 *  ===================
 *  ...
 */

// Render function
export default function login(props) {
    // Return formatted template
    return {
        title: 'Arthaus | Admin',
        assets: [],
        allowGoogleLogin: false
    };
}
