import React from 'react';

// material-ui
import { makeStyles, useTheme } from '@material-ui/styles';

// import image assets
import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/logo.svg';
import logoLarge from 'assets/images/logo-lg.svg';

// ===========================|| LOGO SVG ||=========================== //

// Styled constants
const useStyles = makeStyles((theme) => ({
    container: {
        paddingLeft: '35px'
    },
    image: {
        width: '120px'
    },
    containerLarge: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '10%'
    },
    imageLarge: {
        width: '200px',
        margin: '0 auto'
    }
}));

const Logo = (props) => {
    const { format } = props;

    const classes = useStyles();
    const theme = useTheme();

    if (format && format === 'large') {
        return (
            <div className={classes.containerLarge}>
                <img className={classes.imageLarge} src={logoLarge} alt="Arthaus" />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <img className={classes.image} src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" />
        </div>
    );
};

export default Logo;
